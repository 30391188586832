import { useState, useMemo } from "react";
import ReactGA from "react-ga4";
import axios, { type AxiosResponse } from "axios";
import { useQuery } from "react-query";

import api from "@/api";
import type { IExperienceDto } from "@/interfaces/rest/experience.dto";
import useConfig from "@/providers/configProvider/useConfig";
import { CameraNameEnum } from "@/interfaces/enums/cameraName.enum";
import { QueryKeyEnum } from "@/interfaces/enums/queryKeys.enum";

import { IExperienceNameEnum } from "@/interfaces/enums/experience.enum";
import type { GeneralResponse } from "@/interfaces/rest/response.types";
import type { IDataForTrack, IExperienceState } from "./experience.types";

const tempSkyHighExperienceMock: IExperienceDto = {
  experienceId: 5,
  title: "SkyHigh Experience",
  name: IExperienceNameEnum.SkyHigh,
  experienceTypeId: 1,
  settings: {
    countdownFrom: 3,
    noActivityTimer: 30,
    areYouStillHereTimer: 15,
    noActivityTimeForScan: 30,
  },
  selectedDevice: {
    deviceId: 1,
    deviceType: "Camera",
    captureTypeId: 10813,
    uuId: "00000000-0000-0000-0000-000000000000",
    ip: "10.32.13.109",
    details: {
      description: "description",
      shortDescription: "shortDescription",
      stationIdentifier: "stationIdentifier",
    },
    captureType: "SkyHigh",
    endpointBaseUrl: "",
  },
};

const useExperienceState = (): IExperienceState => {
  const { config } = useConfig();
  const { experienceId, deviceUuid } = config || {};
  const [barcode, setBarcode] = useState<string>("");

  const { data: experience, isError: isExperienceError } = useQuery<IExperienceDto>(
    QueryKeyEnum.experienceList,
    () => {
      if (experienceId === 5) {
        return tempSkyHighExperienceMock;
      }

      return axios
        .get(api.getExperienceWithSelectedDeviceById(experienceId ?? -1, deviceUuid ?? ""))
        .then((res: AxiosResponse<GeneralResponse<IExperienceDto>>) => {
          axios.defaults.baseURL = res.data.data.selectedDevice?.endpointBaseUrl || axios.defaults.baseURL;
          return res.data.data;
        })
        .catch((error) => {
          console.error("Error fetching experience data: ", error);
          throw error;
        });
    },
    { enabled: !!experienceId, retry: 2 },
  );

  const handleBarcodeChange = (newBarcode: string): void => {
    setBarcode(newBarcode);
  };

  const collectGaData = (eventName: string, data?: IDataForTrack): void => {
    if (experience) {
      ReactGA.gtag("event", eventName, {
        time_stamp: new Date().toLocaleString(),
        device_id: CameraNameEnum[experience.name],
        ...data,
      });
    }
  };

  return useMemo(
    () => ({
      experience,
      isExperienceError,
      barcode,
      handleBarcodeChange,
      collectGaData,
    }),
    [experience, isExperienceError, barcode],
  );
};

export default useExperienceState;
